export default [
  {
    title: 'Tổng quan',
    icon: 'HomeIcon',
    route: 'overview',
    action: 'read',
    resource: 'Overview',
  },
  {
    title: 'Tổng quan',
    icon: 'HomeIcon',
    route: 'overview-my-team',
    action: 'read',
    resource: 'OverviewMyTeam',
  },
]
