<template>
  <b-nav-item-dropdown
    ref="dropdown-notification"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNoti"
        badge-classes="bg-danger"
        class="text-body"
        :class="unreadNoti ? 'bell-animation' : ''"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <div class="dropdown-menu-header custom-border-top-1px">
      <div class="dropdown-header d-flex justify-content-between">
        <h4 class="notification-title mb-0">
          Thông báo
        </h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          :disabled="!canMakeAllRead"
          @click="makeAllRead()"
        >Đánh dấu đã đọc
        </b-button>
      </div>
    </div>

    <!-- Notifications -->
    <div
      v-if="notificationList.length"
      ref="notifications-wrapper"
      class="dropdown-scroll"
    >
      <div
        v-for="n in notificationList"
        :key="n.id"
        class="p-50 hover-bg-gains position-relative border-bottom"
        @click="handleCLickNotiItem(n)"
      >
        <b-media class="font-small-3">
          <template #aside>
            <b-avatar
              v-if="n.actor"
              :variant="`light-primary`"
              size="48"
              :src="n.data && n.data.avatar"
              :text="n.actor.split(' ').pop()"
            />
          </template>
          <p
            class="media-heading d-flex justify-content-between"
            :class="n.status==='unread' ? 'text-primary' : ''"
          >
            <span>
              <span class="font-weight-bolder">
                {{ n.actor }}
              </span>
              thông báo - <small>{{ formatDate(n.created_at, true) }}</small>
            </span>
          </p>
          <small
            :class="n.status==='unread' ? 'text-dark' : ''"
            class="notification-text"
          >{{ n.content }}</small>
          <b-button
            v-if="n.status==='unread'"
            variant="flat-primary"
            size="sm"
            class="check-read-icon p-25"
            @click="e => handleMakeRead(n, e)"
          >
            <feather-icon
              icon="CheckIcon"
              size="24"
            />
          </b-button>
        </b-media>
      </div>
    </div>

    <div
      v-else
      class="text-center pt-2 pb-1"
    >
      Bạn không có thông báo nào!
    </div>

    <div
      v-if="notificationList.length"
      class="text-center text-primary p-1"
    >
      {{ hasFetchAll ? 'Đã hết thông báo' : 'Kéo xuống để tài thêm' }}
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BAvatar, BButton, BMedia, BNavItemDropdown,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import http from '@/global/http'
import store from '@/store'
import {
  computed, watch, onUnmounted, ref,
} from '@vue/composition-api'
import notificationStoreModule from './notificationStoreModule'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      timer: null,
    }
  },
  watch: {
    notifications(n) {
      setTimeout(() => {
        if (!n || !n.length) return
        const $el = this.$refs['notifications-wrapper']
        $el.addEventListener('scroll', this.handleScroll)
      }, 100)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.fetchNotifications()
    this.timer = setInterval(() => {
      this.currentPage = 0
      this.fetchNotifications()
    }, 30000)
  },
  methods: {
    handleCLickNotiItem(noti) {
      if (noti.type === 'department_note') {
        this.gotoMyTeam(noti)
      } else this.gotoContractDetail(noti)
    },
    handleScroll(event) {
      const maxHeight = event.target.scrollHeight
      const currentScrollHeight = event.target.scrollTop
      if (maxHeight - currentScrollHeight <= 600) {
        if (!this.isLoadingNoti) {
          this.isLoadingNoti = true
          // còn noti để mà lấy, nếu nhỏ hơn thì là hết rồi
          if (this.notifications.length === this.perPage) {
            this.currentPage += 1
          } else {
            this.hasFetchAll = true
          }
        }
      }
    },
    handleMakeRead(c, e) {
      e.stopPropagation()
      this.makeRead(c)
    },
    gotoContractDetail(c) {
      const id = c.data.contract_id
      this.$refs['dropdown-notification'].$refs.toggle.$el.click()
      this.makeRead(c)
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
        query: { t: Date.now() },
      })
    },
    gotoMyTeam(noti) {
      this.$refs['dropdown-notification'].$refs.toggle.$el.click()
      this.makeRead(noti)
      const timeFilter = noti?.data?.data?.month || ''
      const monthFilter = timeFilter.split('-')[0] || ''
      const yearFilter = timeFilter.split('-')[1] || ''
      this.$router.push({
        name: 'reports-my-team',
        query: {
          t: Date.now(),
          ref: 'reportsTeam-teamNote',
          ...(monthFilter && { monthFilter }),
          ...(yearFilter && { yearFilter }),
        },
      })
    },
  },
  setup() {
    const NOTIFICATION_STORE_MODULE_NAME = 'notification'
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, notificationStoreModule)
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    const notificationList = ref([])
    const notifications = ref([{}])
    const currentPage = ref(0)
    const perPage = ref(10)
    const isLoadingNoti = ref(false)
    const unreadNoti = ref(0)
    const hasFetchAll = ref(false)
    const canMakeAllRead = computed({
      get: () => unreadNoti.value,
      set: () => {
      },
    })

    const updateDocumentTitle = () => {
      if (unreadNoti.value) {
        document.title = `(${unreadNoti.value}) Song long`
      }
    }

    const fetchNotifications = filter => {
      if (filter?.status === '') {
        hasFetchAll.value = true
      }
      const queryString = http.buildQuery({
        page: currentPage.value,
        perpage: perPage.value,
        status: hasFetchAll ? '' : 'unread',
        ...filter,
      }) || ''
      return http.api(store.dispatch('notification/fetchNotifications', queryString), res => {
        notifications.value = res?.data.filter(n => n.title) || []
        if (!notifications.value.length) {
          hasFetchAll.value = true
        }
        if (currentPage.value === 0) {
          notificationList.value = notifications.value
        } else {
          notificationList.value.push(...notifications.value)
          notificationList.value.sort((a, b) => (a.status > b.status ? -1 : 1))
        }
        updateDocumentTitle()
        isLoadingNoti.value = false
        unreadNoti.value = res?.total_unread || 0
      })
    }

    const countUnread = () => http.api(store.dispatch('notification/countUnread'), () => {
    })

    const makeRead = ({ id }) => http.api(store.dispatch('notification/makeRead', { id }), () => {
      const found = notifications.value.find(n => n.id === id)
      found.status = 'read'
      notifications.value.sort((a, b) => (a.status > b.status ? -1 : 1))
      updateDocumentTitle()
    })

    const makeAllRead = () => http.api(store.dispatch('notification/makeAllRead'), () => {
      for (let i = 0; i < notifications.value.length; i += 1) {
        notifications.value[i].status = 'read'
        unreadNoti.value = 0
      }
      updateDocumentTitle()
    })

    watch([currentPage, perPage], () => {
      fetchNotifications()
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notificationList,
      notifications,
      hasFetchAll,
      canMakeAllRead,
      unreadNoti,
      currentPage,
      perPage,
      isLoadingNoti,
      fetchNotifications,
      countUnread,
      makeRead,
      makeAllRead,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.check-read-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
