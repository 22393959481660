<template>
  <b-nav-item-dropdown
    v-if="userData && userData.fullname"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav minw-110px">
        <p class="user-name font-weight-bolder mb-25">
          {{ userData.fullname || userData.username }}
        </p>
        <span
          v-if="userData.user_role"
          class="user-status"
        >{{ userData.user_role.name }}
          <template
            v-if="userData.department && userData.department.code && userData.department.code.toLowerCase().startsWith('d')"
          >- {{ userData.department.name }}</template>
        </span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullname"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="triggerUploadAvatar"
    >
      <feather-icon
        size="16"
        icon="ImageIcon"
        class="mr-50"
      />
      <span>Đổi avatar</span>
      <uploads
        id="uploads"
        ref="uploads"
        type="button"
        class="hidden-all-item d-none upload-image"
        :files="files"
        :options="{textDisplay: 'Tải ảnh lên'}"
        :method-name="'uploadAvatar'"
        @file-uploaded="handleUploaded"
        @file-removed="handleRemoved"
        @all-done="handleAllDone"
      />
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="openModalChangePassword"
    >
      <feather-icon
        size="16"
        icon="AlertTriangleIcon"
        class="mr-50"
      />
      <span>Đổi mật khẩu</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Đăng xuất</span>
    </b-dropdown-item>

    <modal-change-password
      id="modal-user-change-password"
      ref="modal-user-change-password"
      modal-mode="user-change-password"
      :user="userData"
    />
  </b-nav-item-dropdown>
  <b-button
    v-else
    variant="flat-danger"
    @click="logout"
  >
    Đăng xuất
  </b-button>
</template>

<script>
import {
  BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import Uploads from '@core/components/uploads/Uploads.vue'
import { onUnmounted, ref } from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ModalChangePassword,
    BButton,
    Uploads,
  },
  data() {
    return {
      userData: {},
      avatarText,
    }
  },
  watch: {
    files(n) {
      if (!n && !n.length) return
      const last = n[n.length - 1] || {}
      Object.assign(this.userData, { avatar: last.thumbnail || '' })
    },
  },
  created() {
    this.userData = this.$getItem('userData') || {}
  },
  methods: {
    triggerUploadAvatar() {
      this.$refs.uploads.$el.click()
    },
    openModalChangePassword() {
      this.$refs['modal-user-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    handleUploaded(files = []) {
      this.files.push(...files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAllDone() {
      this.$action(this.updateMe(this.userData))
    },
    updateMe(data) {
      return http.api(store.dispatch('user/updateMe', data), res => {
        this.userData = res.data
        localStorage.setItem('userData', JSON.stringify(this.userData))
      })
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    const files = ref([])

    return {
      files,
    }
  },
}
</script>
