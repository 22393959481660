export default [
  {
    header: 'Báo cáo',
    action: 'read',
    resource: 'ReportsTitle',
  },
  {
    title: 'Tổng hợp',
    route: 'reports',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Reports',
  },
  {
    title: 'Kế hoạch Thu nợ',
    route: 'debt-collection',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'DebtCollectionMenu',
  },
  {
    title: 'Đội của tôi',
    route: 'reports-my-team',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'ReportsMyTeam',
  },
  {
    title: 'Cá nhân',
    route: 'reports-me',
    icon: 'UserIcon',
    action: 'read',
    resource: 'ReportsUser',
  },
]
