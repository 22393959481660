export default [
  {
    header: 'Kế hoạch làm việc',
    action: 'read',
    resource: 'PlansTitle',
  },
  {
    title: 'Danh sách',
    route: 'plans-list',
    icon: 'ListIcon',
    action: 'read',
    resource: 'Plans',
  },
]
