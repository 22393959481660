export default [
  {
    header: 'Thiết lập',
    action: 'read',
    resource: 'Settings',
  },
  {
    title: 'Nhân viên',
    route: 'settings-users',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Settings',
  },
  {
    title: 'Hệ thống',
    route: 'settings-parameters',
    icon: 'GitPullRequestIcon',
    action: 'read',
    resource: 'Settings',
  },
  {
    title: 'Phân quyền',
    route: 'settings-roles',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'Settings',
  },
]
